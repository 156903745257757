/* eslint-disable camelcase */
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import {
  PageLayout,
  Services,
  PageHeading,
  LocationSection,
  FAQ,
} from 'components';
import { ServicesPageType } from 'types';

const ServicesPage: FC<ServicesPageType> = ({ data }: any) => {
  const {
    title,
    tagline,
    description,
    pageTheme,
    slug,
    seo,
    service_pages,
    banner,
    faq: { title: faqTitle, tagline: faqTagline, faqs },
  } = data.ServicesPage;
  const { locationsSection } = data.strapiContactUsPage;

  return (
    <PageLayout
      title={title}
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      hasContainerClass
      className={`${pageTheme}`}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      faqs={faqs}
    >
      <PageHeading
        title={title}
        subtitle={tagline}
        description={description}
        level={1}
      />
      <Services services={service_pages} servicesPageSlug={slug} />
      <section className="contact-page-location-section">
        <LocationSection
          title={locationsSection.title}
          tagline={locationsSection.tagline}
          description={locationsSection.description}
          image={locationsSection.image}
        />
      </section>
      <section className="home-page-faq-section">
        <PageHeading title={faqTitle} subtitle={faqTagline} />
        <FAQ Faqs={faqs} />
      </section>
    </PageLayout>
  );
};

export default ServicesPage;
export const query = graphql`
  query ServicePageQuery {
    ServicesPage: strapiServicesPage {
      title
      tagline
      description: pageDescription
      pageTheme
      slug
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      faq {
        tagline
        title
        faqs {
          answer
          title
        }
      }
      service_pages {
        slug
        pageType
        promo {
          id
          content
          title
          icon
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600, height: 600)
              }
            }
          }
        }
      }
    }
    strapiContactUsPage {
      locationsSection {
        title
        tagline
        description
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
